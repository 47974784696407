.multi-select-dropdown {
    position: relative;
}

.selected-options {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}

.selected-option {
    background-color: #e9002f;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    align-items: center;
}

.remove-option {
    margin-left: 5px;
    cursor: pointer;
    font-weight: normal;
    /* Make it thinner */
    font-size: 12px;
    /* Make it smaller */
}

.dropdown-options {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333333;
    font-family: 'Montserrat', sans-serif;
    transition: border-color 0.3s;
    min-height: 30px;
    max-height: 60px;
    overflow-y: auto;
}

.dropdown-option {
    padding: 5px;
    font-family: 'Montserrat', sans-serif;
    color: #333333;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.dropdown-option:hover,
.dropdown-option.selected:hover {
    background-color: #d9002f;
    color: #ffffff;
}

.dropdown-option.selected {
    background-color: #e9002f;
    color: #ffffff;
}

@media (max-width: 768px) {
    .dropdown-options {
        padding-top: 2px;
        max-height: 80px;
        padding-bottom: 2px;
        font-size: 11px;
    }

    .selected-option {
        padding: 2px 5px;
        font-size: 16px;
        border-radius: 5px;
        font-family: 'Montserrat', sans-serif;
    }
}