:root {
  --themed-font-family: 'Montserrat', sans-serif;
  --header-height: 90px;
  --header-body-height-buffer: 10px;
  --start-body: calc(var(--header-height) + var(--header-body-height-buffer));
  --start-body-vh: 7vh;
  --body-height-vh: calc(100vh - var(--start-body-vh));
  --player-with-ticker-height: 265px;
  --background-color-primary: #030404;
  --msblack: #030404;
  --mswhite: #ffff;
  --msred: #e9002f;
  --theme-color-primary: #e9002f;
  --theme-color-secondary: #a30606;
  --theme-color-hover-primary: #ff073a;
  --theme-color-hover-secondary: #c21010;
  --theme-gradient: linear-gradient(to left, var(--theme-color-primary), var(--theme-color-secondary));
  --themed-ticker-gradient: linear-gradient(to right, #a306069d, #e93600b8);
  --theme-gradient-hover: linear-gradient(to left, var(--theme-color-hover-primary),
      var(--theme-color-hover-secondary));
  --themed-text-color: #ff073a;
  --themed-input-background-color: rgba(32, 25, 21, 0.699);
  --themed-dropdown-background-color: rgba(32, 25, 21, 0.927);
  --themed-input-placeholder-text-color: #fef2e978;
  --themed-input-text-color: #fef2e9;
  --themed-containers-gradient-background: linear-gradient(to top, #4f363652, #2d282852);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color-primary);
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
