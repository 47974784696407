.tabs {
    display: flex;
    margin-bottom: 20px;
    gap: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    background-color: var(--mswhite);
    border-radius: 25px;
    transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, transform 0.3s;
    position: relative;
}

.tab:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.tab.active {
    color: var(--mswhite);
    background-color: #e9002f;
}

.tab.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e9002f;
    transition: transform 0.3s ease-out;
}

.tab.active.show-arrow::after {
    transform: translateX(-50%) translateY(10px);
}

.tabs .tab:not(.active) {
    background-color: #ffffff;
}

@media (max-width: 768px) {
}