.header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme--themed-input-text-color);
    background-color: var(--background-color-primary);
    z-index: 1000;
    height: var(--header-height);
    width: 100%;
}

.header.scrolled {
    border-bottom: 1px solid rgba(255, 255, 255, 0.163);
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    /* Limit the maximum width for better responsiveness */
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.header-left-group,
.header-middle-group,
.header-right-group {
    display: flex;
    align-items: center;
    padding: 10px;
}

.header-left-group {
    justify-content: start;
}

.header-middle-group {
    flex: 2;
    justify-content: center;
}

.header-right-group {
    justify-content: end;
}

.ms-logo {
    cursor: pointer;
}

.nav-center {
    width: 100%;
}

nav ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

nav li {
    margin-left: 20px;
}

nav a {
    color: white;
    text-decoration: none;
    cursor: pointer;
}

nav a:hover {
    text-decoration: none;
    color: var(--theme-color-primary);
}

.hamburger-icon {
    font-size: 2rem;
    cursor: pointer;
}

/* Dropdown menu styles */
.dropdown-menu {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: var(--background-color-primary);
    border: 1px solid #ddd;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    max-height: 0;
    /* Initially hidden */
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    /* Slide down animation */
}

.dropdown-menu.open {
    max-height: 300px;
    /* Adjust based on content height */
}

.dropdown-menu .menu-item {
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    display: block;
    width: 100%;
    /* Ensure full-width for the items */
}

.dropdown-menu .menu-item:hover {
    background-color: var(--theme-color-primary);
    color: white;
}

@media (max-width: 768px) {

    .header-middle-group,
    .header-right-group {
        display: none;
    }

    .hamburger-menu {
        display: flex;
        justify-content: end;
    }

    .header-container {
        padding-left: 5px;
        /* padding-right: 10px; */
    }
}

.no-underline {
    text-decoration: none;
}