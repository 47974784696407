.button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: transform 0.3s ease;
    min-width: 150px;
}

.button.contained {
    color: #fff;
}

.button.contained.primary {
    background-color: #e9002f;
}

.button.contained.primary::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    transition: all 0.4s;
}

.button.contained.primary:hover::before {
    left: 100%;
}

.button.contained.primary:hover {}

.button.contained.secondary {
    background-color: #fff;
    color: #000;
}

.button.contained.secondary::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent);
    transition: all 0.4s;
}

.button.contained.secondary:hover::before {
    left: 100%;
}

.button.contained.secondary:hover {}

.button.outlined {
    background-color: transparent;
    border: 2px solid;
    padding: 13px 28px;
}

.button.outlined.primary {
    border-color: #e9002f;
    color: #e9002f;
}

.button.outlined.primary:hover {
    background-color: rgba(233, 0, 47, 0.1);
}

.button.outlined.secondary {
    border-color: #fff;
    color: #fff;
}

.button.outlined.secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.button.text {
    background-color: transparent;
    border: none;
}

.button.text.primary {
    color: #e9002f;
}

.button.text.primary:hover {
    background-color: rgba(233, 0, 47, 0.1);
}

.button.text.secondary {
    color: #fff;
}

.button.text.secondary:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.button span {
    position: relative;
    z-index: 1;
}

.button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .button {
        padding: 10px 20px;
        font-size: 14px;
        min-width: 120px;
    }

    .button.outlined {
        padding: 8px 18px;
    }
}