.early-access-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #fff;
}

.early-access-header {
    text-align: center;
    margin-bottom: 40px;
}

.early-access-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 120px;
}

.early-access-left {
    flex: 1;
    margin-right: 20px;
}

.access-benefits {
    list-style: none;
    padding: 0;
}

.access-benefits li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.benefit-icon {
    color: #e9002f;
    font-size: 12px;
}

.early-access-right {
    flex: 2;
    margin-left: 20px;
}

.early-access-form {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    /* Centering for mobile */
    margin: 0 auto;
}

.early-access-form form {
    display: flex;
    flex-direction: column;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.form-group {
    margin-bottom: 20px;
}

.input-label {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    font-size: 14px;
}

.required-asterisk {
    color: #e9002fad;
    font-size: 1rem;
    margin-left: 5px;
    vertical-align: super;
}

.address-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;
}

.address-inputs>div {
    flex: 1;
}

.address-inputs .input-label {
    display: flex;
    align-items: center;
    min-height: 20px;
}

.early-access-form input,
.early-access-form select,
.early-access-form textarea {
    padding: 15px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333333;
    transition: border-color 0.3s;
    font-family: 'Montserrat', sans-serif;
}

.early-access-form input::placeholder,
.early-access-form select::placeholder,
.early-access-form textarea::placeholder {
    font-family: 'Montserrat', sans-serif;
    color: #a9a9a9;
    font-size: 14px;
}

.early-access-form input:focus,
.early-access-form select:focus,
.early-access-form textarea:focus {
    border-color: #e9002f;
    background-color: #ededed;
    outline: none;
}

.submit-button {
    width: 100%;
    padding: 10px 20px;
    background-color: var(--theme-color-primary);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s, transform 0.3s;
}

.submit-button:hover {
    background-color: #c70027;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .early-access-form input,
    .early-access-form select,
    .early-access-form textarea {
        padding: 5px;
        font-size: 11px;
    }

    .early-access-form input::placeholder,
    .early-access-form select::placeholder,
    .early-access-form textarea::placeholder {
        font-size: 11px;
    }

    .early-access-header {
        margin-bottom: 20px;
    }

    .early-access-right {
        margin-left: 0;
    }

    .early-access-content {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 20px;
    }

    .early-access-left {
        margin-bottom: 20px;
    }

    .early-access-right {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }

    .early-access-form {
        padding: 20px;
        width: 90%;
        max-width: 600px;
    }

    .input-group {
        margin-bottom: 15px;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .input-label {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .address-inputs {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 15px;
    }

    .early-access-form input,
    .early-access-form select,
    .early-access-form textarea {
        padding: 10px;
    }

    .early-access-form .multi-select-dropdown .dropdown-options .dropdown-option {
        font-size: 11px;
        height: 20px;
    }
}