.base-layout {
    position: relative;
    color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
}

.base-layout::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    opacity: 0.2;
    z-index: -1;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    top: var(--header-height);
}