.product-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 20px;
    margin-bottom: 60px;
    margin-top: 40px;
    min-height: 100vh;
}

.product-header {
    text-align: center;
    margin-bottom: 40px;
}

.header-title {
    margin: 20px 0;
}

.product-grid {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.product-item.reverse {
    flex-direction: row-reverse;
}

.product-image {
    width: 60%;
    max-width: 400px;
    height: auto;
}

.product-text {
    max-width: 600px;
}

.product-item-title {
    margin-bottom: 20px;
}

.product-item-description {
    line-height: 1.5;
}

@media (max-width: 768px) {
    .product-image {
        width: 40%;
        max-width: 200px;
        height: auto;
    }
}