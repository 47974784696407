.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
}

.logo h1 {
    font-size: 1.5rem;
    margin: 0;
}

.ms-logo-text {
    display: flex;
    flex-direction: row;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.ms-logo-icon img {
    margin-top: 2px;
}

@media (max-width: 768px) { 
   
}