.alert {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffcc00;
    color: #333;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    margin: 20px;
}

.alert-icon {
    margin-right: 10px;
    font-size: 20px;
}

.alert-message {
    font-weight: bold;
}
