.ms-landing-home-intro-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin-top: 80px;
}

.intro-title,
.intro-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.intro-description {
    width: 100%;
    max-width: 980px;
    padding-top: 20px;
    margin-left: 90px;
    margin-right: 90px;
}

.cta-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 80px;
}

.cta-button-container {
    display: flex;
    gap: 20px;
}

.cta-watch-demo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-overlay.show {
    opacity: 1;
}

.modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-50px);
    transition: transform 0.3s ease-in-out;
}

.modal-content.show {
    transform: translateY(0);
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.modal-body {
    text-align: center;
}

@media (max-width: 768px) {
    .cta-container,
    .intro-description {
        max-width: 300px;
    }
}

.team-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    margin-top: 40px;
    padding: 40px 20px;
}

.team-header {
    text-align: center;
    margin-bottom: 40px;
}

.header-title {
    margin: 20px 0;
    color: #fff;
}

.header-description {
    max-width: 800px;
    margin: 0 auto;
    color: #909090;
}

.team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.team-item {
    perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.team-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.team-item:hover .team-card {
    transform: rotateY(180deg);
}

.team-card-front,
.team-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.team-card-front {
    background-color: #22222253;
}

.team-card-back {
    background-color: #ffffff;
    color: #000000;
    transform: rotateY(180deg);
}

.team-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
}

.team-role {
    color: #fff;
}

.team-role strong {
    display: block;
    font-size: 16px;
}

.team-role p {
    margin: 0;
    font-size: 14px;
}

@media (max-width: 768px) {
    .team-item {
        /* width: 250px; */
        height: 350px;
    }

    .team-grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
    }
}