.features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 40px 20px;
    margin-top: 40px;
    min-height: 100vh;
}

.features-header {
    text-align: center;
    margin-bottom: 40px;
}

.header-title {
    margin: 20px 0;
}

.header-description {
    max-width: 800px;
    margin: 0 auto;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.feature-item {
    perspective: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.feature-item:hover .feature-card {
    transform: rotateY(180deg);
}

.feature-card {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feature-card-front,
.feature-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.feature-card-front {
    background-color: #22222253;
}

.feature-card-back {
    background-color: #ffffff;
    transform: rotateY(180deg);
}

.feature-icon {
    margin-bottom: 15px;
    color: var(--theme-color-primary);
}

@media (max-width: 768px) {
    .feature-card-front,
        .feature-card-back {
            width: 80%;
        }
}