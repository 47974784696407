.ms-text.regular {
    letter-spacing: normal;
}

.ms-text.spaced {
    letter-spacing: 2px;
}

.ms-text.condensed {
    letter-spacing: -0.05em;
}

.ms-text.thin {
    font-weight: 200;
}

.ms-text.regular {
    font-weight: 400;
}

.ms-text.bold {
    font-weight: 700;
}