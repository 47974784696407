.stay-updated-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
}

.stay-updated-header {
    text-align: center;
}

.stay-updated-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.email-input {
    padding: 20px;
    font-size: 16px;
    border: none;
    outline: none;
    width: 600px;
}

.subscribe-button {
    border-radius: 0;
    padding: 19px;
}

@media (max-width: 768px) {
    .stay-updated-form {
        width: 60%;
    }
}