.footer-section {
    background-color: #22222256;
    width: 100%;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
}

.footer-links,
.footer-social {
    flex: 1;
    margin: 0 20px;
}

.footer-links h4,
.footer-social h4 {
    margin-bottom: 10px;
    font-size: 18px;
    color: var(--theme-color-primary);
}

.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-links ul li {
    margin-bottom: 5px;
}

.footer-links ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
}

.footer-links ul li a:hover {
    color: #e9002f;
}

.footer-social .social-icons {
    display: flex;
    gap: 15px;
}

.social-icons {
    padding-top: 15px;
}

.footer-social .social-icons a {
    color: #fff;
    font-size: 20px;
    transition: color 0.3s;
}

.footer-social .social-icons a:hover {
    color: #e9002f;
}

.footer-bottom {
    border-top: 1px solid #444;
    padding-top: 10px;
    width: 100%;
    text-align: center;
}