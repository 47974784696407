.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.modal-overlay.show {
    opacity: 1;
}

.modal-content {
    background: #1e1d1d;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(63, 52, 52, 0.415);
    transform: translateY(-50px);
    transition: transform 0.3s ease-in-out;
}

.modal-content.show {
    transform: translateY(0);
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.modal-body {
    text-align: center;
}

@media (max-width: 768px) {
    .modal-content {
        max-width: 200px;
    }
}