.pricing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 80px 20px;
    background-color: #000;
}

.pricing-header {
    text-align: center;
    margin-bottom: 50px;
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.pricing-card {
    background-color: #1c1c1ca7;
    border-radius: 12px;
    padding: 50px 40px;
    text-align: center;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 400px;
    max-width: 300px;
    min-width: 200px;
}

.pricing-card:hover {
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.4);
    background-color: #222222d1;
}

.card-header {
    position: relative;
    background: rgba(233, 0, 47, 0.2);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    height: 200px;
    width: 250px;
}

.plan-title {
    font-size: 28px;
    margin-bottom: 10px;
    color: #e9002f;
}

.plan-price {
    font-size: 40px;
    margin-bottom: 20px;
    color: #fff;
}

.plan-price span {
    font-size: 20px;
    color: #909090;
}

.plan-features {
    list-style: none;
    padding: 0;
    margin: 0 0 20px 0;
    color: #909090;
    text-align: left;
}

.plan-features li {
    margin-bottom: 15px;
    font-size: 18px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 25px;
}

.plan-features li:before {
    content: "✓";
    color: #e9002f;
    position: absolute;
    left: 0;
}

.get-started-button {
    display: flex;
    justify-content: center;
    padding: 20px 40px;
    background-color: #e9002f;
    color: #fff;
    text-decoration: none;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 20px;
}

.get-started-button:hover {
    background-color: #d70027;
    transform: scale(1.05);
}

.pro {
    background-color: #2e2e2e;
    border: 2px solid #e9002f;
}

@media (max-width: 768px) {
    .pricing-grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pricing-section {
        padding: 40px 10px;
    }

    .pricing-header {
        margin-bottom: 30px;
    }

    .pricing-card {
        padding: 30px 20px;
    }

    .card-header {
        padding: 15px;
        height: auto;
        /* width: 100%; */
    }

    .get-started-button {
        padding: 15px 30px;
    }
}